import Page from "./Pages/Page";
import LoadingScreen from "./Pages/LoadingScreen/LoadingScreen";
import React, { useState, useEffect } from "react";
import FontFaceObserver from "fontfaceobserver";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadImages = () => {
      const images = Array.from(document.images);
      const promises = images.map((image) => {
        return new Promise((resolve, reject) => {
          if (image.complete) {
            resolve();
          } else {
            image.onload = resolve;
            image.onerror = reject;
          }
        });
      });
      return Promise.all(promises);
    };

    const loadFonts = () => {
      const fontA = new FontFaceObserver("FontA");
      const fontB = new FontFaceObserver("FontB");
      return Promise.all([fontA.load(), fontB.load()]);
    };

    const loadResources = async () => {
      try {
        await Promise.all([loadImages(), loadFonts()]);
        setLoading(false);
      } catch (error) {
        console.error("Error loading resources:", error);
        setLoading(false); // Proceed even if there are errors
      }
    };

    loadResources();
  }, []);

  return (
    <div className="App">
      {loading && <LoadingScreen />}
      {!loading && <Page />}
    </div>
  );
}

export default App;
