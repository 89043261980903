import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { RiDownload2Line } from "react-icons/ri";

export default function Project({ src, ytLink, downloadLink, name }) {
  return (
    <div className="col-lg-3 col-md-6 col-sm-12 project">
      <img className="project_img" src={src} alt="" />
      <div className="project_data">
        <img src={`${process.env.PUBLIC_URL}/imgs/Flicha.png`} alt="" width="40px" />
        <h3 className="project_heading" style={{ display: "inline" }}>{name}</h3>
      </div>
      <div className="project_links">
        {downloadLink !== "" && (
          <a href={downloadLink} target="_blank" rel="noopener noreferrer">
            <RiDownload2Line />
          </a>
        )}
        <a href={ytLink} target="_blank" rel="noopener noreferrer">
          <img
            src="https://cdn3.iconfinder.com/data/icons/social-network-30/512/social-06-512.png"
            alt="YouTube Link"
          />
        </a>
      </div>
    </div>
  );
}
