import React from 'react'
import "./Certificate.css"

export default function Certificate({src,name,link}) {
  return (
    <div className='certificate col-lg-6 col-m-12'>
        <img src={src} alt="" srcset="" />
        <a href={link} target="_blank">{name}</a>
    </div>
  )
}
