import React from 'react';
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="loadingScreen">
      <img src={`${process.env.PUBLIC_URL}/imgs/Logo.png`} className='image' alt="" />
    </div>
  );
};

export default LoadingScreen;

