import React from "react";
import "./Welcome.css";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function Welcome() {
  return (
    <div className="main">
      <div className="content">
        <div>
          <h1 className="name">
            Abderraouf <br />
            Gaouer
          </h1>
          <p className="heading">VR / Game Developer</p>
          <p>
            I'm a software engineering graduate from USTHB Algeria. With 2 years
            of experience in Unity, I specialize in AR, VR, and 3D game
            development. I've demonstrated teaching, leadership, and teamwork
            through volunteering and freelancing. Additionally, I've worked as
            AR and 3D remotely and VR for freelance. My Unity expertise includes
            OOP, Unity networking, Cinemachine, Timeline (for short movies),
            NavMesh, and more. I'm adept at VR and AR tools such as AR
            Foundation and XR Interaction Toolkit.
          </p>
          <p>
            <a
              href="https://docs.google.com/document/d/1voIR-A9FiYhwGuZCgoZ0wqBW4ZFNdXiNsOu0xuV0hII/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaExternalLinkAlt /> Open resume
            </a>
          </p>
        </div>
        <div className="img_parent">
          <img
            className="rounded_image"
            src={`${process.env.PUBLIC_URL}/imgs/MyPicture.png`}
            alt="Profile"
          />
        </div>
      </div>
      <div className="mini-text">
        <img
          src={`${process.env.PUBLIC_URL}/imgs/Flicha.png`}
          width={"40px"}
          alt="Arrow"
        />
        <p>Work with me today</p>
      </div>
    </div>
  );
}
