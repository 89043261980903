import React from "react";
import "./Experiences.css";
export default function Experience({ src, name, desc, details, desc2, duration}) {
  return (
    <div className="experience col-lg-6 col-m-12">
      <img src={src} alt="" srcset="" />
      <div>
        <div className="exp_heading">
          <h3>{name}</h3>
          <h3 className="duration">{duration}</h3>
        </div>

        <p>{desc}</p>
        <ul>
          {details.map((detail) => {
            return <li>{detail}</li>;
          })}
        </ul>
        <p>{desc2}</p>
      </div>
    </div>
  );
}
