import Projects from "./Projects/Projects";
import Welcome from "./Welcome/Welcome";
import Certificates from "./Certifications/Certificates";
import Experiences from "./Experience/Experiences";
import "../index.css";
import Contact from "./Contact/Contact ";

export default function Page() {
  return (
    <div>
      <Welcome />
      <Projects />
      <Experiences />
      <Certificates />
      <Contact />
    </div>
  );
}
